<template>
  <div class="container">
    <div class="leftBox">
      <div class="title">联系我们</div>
      <p>
        审核管理软件是广州市景翔信息科技有限公司旗下网站
        <br />任何关于软件的意见与建议，以及相关合作事宜
        <br />欢迎联系我们
      </p>
    </div>
    <div class="rightBox">
      <div class="firmBox">
        <div class="title">企业邮箱</div>
        <div class="text">
          <span>请发送信息至</span>
          <span>aoyu@jeansawn.com</span>
        </div>
      </div>
      <div class="firmBox">
        <div class="title">联系方式</div>
        <div class="text">
          <span>电话与微信同号</span>
          <span>13560068664</span>
          <img src="../../assets/images/wxcode.png" alt />
        </div>
        <div class="text">
          <span>固定电话</span>
          <span>020-39984322</span>
        </div>
      </div>
      <div class="firmBox" style="border-bottom:0">
        <div class="title">建议&问题反馈</div>
        <div class="text" style="margin-bottom:0">
          <span>如您有功能建议问题反馈，可通过一下方式告诉我们</span>
        </div>
        <button @click="relationBtn">联系客服</button>
      </div>
    </div>
    <div class="dialogBox" v-show="show">
      <i class="el-icon-close" @click="close"></i>
      <div class="box">
        <div class="title">联系我们</div>
        <p>需要帮助？请留意，描述您遇到的问题，我们将尽快回复您</p>
        <div class="onLine">在线联系</div>
        <div class="text">如您有功能建议问题反馈，可通过以下方式告诉我们</div>
        <el-form :model="form" ref="form" :rules="rules">
          <el-row :gutter="32">
            <el-col :span="12">
              <el-form-item label="你的姓名" prop="name">
                <el-input v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="你的电话" prop="phoneNum">
                <el-input v-model="form.phoneNum" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="32">
            <el-col :span="12">
              <el-form-item label="反馈类型" prop="type">
                <el-select v-model="form.type" placeholder="请选择" style="width:100%">
                  <el-option
                    v-for="item in feedbackTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="我们如何帮助您？" prop="remark">
                <el-input
                  style="margin-top:0.06rem"
                  type="textarea"
                  :rows="4"
                  placeholder="请描述你的问题"
                  v-model="form.remark"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="添加附件">
                <el-button style="width:100%" class="addBtn" @click="getPicture">请添加图片</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <!-- <el-button class="seeBtn" @click="seePicture">查看图片</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-button type="primary" @click="submitForm">提交留言</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="pictureShow" width="938px">
      <el-upload
        v-show="uploadShow"
        action
        :headers="headers"
        list-type="picture-card"
        ref="upload"
        accept=".JPG, .PNG, .JPEG, .jpg, .png, .jpeg"
        :http-request="uploadFile"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pictureShow = false">取 消</el-button>
        <el-button type="primary" @click="submitPicture">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { baseURL, post } from "../../api/http";
import { compressImage } from "../../modules/CompressImage";
export default {
  data() {
    var validateMobilePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不可为空"));
      } else {
        if (value !== "") {
          var reg = /^1[3456789]\d{9}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的手机号码"));
          }
        }
        callback();
      }
    };
    return {
      show: false,
      form: {},
      feedbackTypeList: [
        {
          value: "功能故障",
          label: "功能故障",
        },
        {
          value: "意见&建议",
          label: "意见&建议",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      pictureShow: false,
      title: "",
      uploadShow: true,
      baseUrl: baseURL[process.env.NODE_ENV + ""],
      headers: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      imgList: [],
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // phoneNum: [{ required: true, message: "请输入电话", trigger: "blur" }],

        phoneNum: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { validator: validateMobilePhone, trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择反馈类型", trigger: "change" },
        ],
        remark: [{ required: true, message: "请填写问题", trigger: "blur" }],
      },
      file: "",
    };
  },
  methods: {
    relationBtn() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    getPicture() {
      this.pictureShow = true;
      this.title = "添加图片";
      this.uploadShow = true;
    },
    seePicture() {
      this.pictureShow = true;
      this.title = "查看图片";
      this.uploadShow = false;
    },
    // 表单重置
    reset() {
      this.form = {
        id: 0,
        name: null,
        phoneNum: null,
        type: null,
        remark: null,
        saveTime: null,
        tenantId: 0,
      };
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    // 图片上传
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            // 压缩后比原来更大，则将原图上传
            //调接口上传图片到服务器
            this.uploadFileReq(e.file);
            // this.file = e.file;
          } else {
            //result压缩结果
            // 压缩后比原来小，上传压缩后的
            //调接口上传图片到服务器
            this.uploadFileReq(result);
            // this.file = result;
          }
        });
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadContactImg", formData)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("上传成功");
            this.imgList.push({ imageAddress: res.value, imagesRemark: "" });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    // 提交图片
    submitPicture() {
      // this.uploadFileReq(this.file);
      this.pictureShow = false;
    },
    // 提交留言
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.id = 0;
          this.form.saveTime = new Date().toISOString();
          this.form.tenantId = 0;
          let queryParams = {
            mContact: this.form,
            lstImage: this.imgList,
          };
          post("/api/Contact", queryParams).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "提交成功",
              });
              this.reset();
              this.$refs.upload.clearFiles();
              this.imgList = [];
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #f1f8fd;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .leftBox {
    .title {
      height: 0.48rem;
      font-size: 0.48rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #050505;
      margin-bottom: 0.5rem;
    }
    p {
      width: 5.21rem;
      height: 1rem;
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 0.4rem;
    }
  }
  .rightBox {
    width: 5.38rem;
    height: 5.45rem;
    background: #ffffff;
    box-shadow: 0px 0.02rem 0.06rem 1px rgba(153, 152, 152, 0.41);
    border-radius: 0.1rem;
    padding: 0.18rem 0.53rem 0 0.56rem;
    box-sizing: border-box;
    .firmBox {
      position: relative;
      border-bottom: 2px solid #e5e8e8;
      .title {
        margin-top: 0.4rem;
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #050505;
        line-height: 0.2rem;
      }
      .text {
        margin-top: 0.26rem;
        margin-bottom: 0.3rem;
        font-family: Microsoft YaHei;
        font-size: 0.16rem;
        line-height: 0.16rem;
        & > span:nth-child(1) {
          font-weight: 400;
          color: #999999;
          margin-right: 0.1rem;
        }
        & > span:nth-child(2) {
          font-weight: bold;
          color: #ff0000;
        }
        img {
          position: absolute;
          top: 0.2rem;
          right: 0.7rem;
          width: 73px;
          height: 72px;
        }
      }
      button {
        margin-top: 0.14rem;
        width: 1.03rem;
        height: 0.36rem;
        background: #e4e9ed;
        border-radius: 0.13rem;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .dialogBox {
    position: absolute;
    // width: calc(100% - 568px);
    // width: calc(100% - 29.58%);
    width: calc(100% - 5.68rem);
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0.02rem 0.06rem 1px rgba(153, 152, 152, 0.41);
    ::v-deep .el-icon-close {
      position: absolute;
      top: 0.35rem;
      right: 0.35rem;
      font-size: 0.14rem;
      cursor: pointer;
    }
    .box {
      width: 6.88rem;
      height: 100%;
      margin: 0.62rem auto;
      font-family: Microsoft YaHei;
      .title {
        font-size: 0.2rem;
        font-weight: bold;
        color: #050505;
        line-height: 0.2rem;
        text-align: center;
      }
      p {
        margin-top: 0.22rem;
        font-size: 0.14rem;
        font-weight: 600;
        color: #333333;
        line-height: 0.14rem;
        text-align: center;
      }
      .onLine {
        margin-top: 0.46rem;
        font-size: 0.2rem;
        font-weight: bold;
        color: #050505;
        line-height: 0.2rem;
      }
      .text {
        margin-top: 0.26rem;
        font-size: 0.16rem;
        font-weight: 400;
        color: #999999;
        line-height: 0.16rem;
        margin-bottom: 0.32rem;
      }
      ::v-deep .el-form-item__label {
        height: 0.14rem;
        font-size: 0.14rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #3d3d3d;
        line-height: 0.14rem;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0.22rem;
      }
      .addBtn {
        background: #e4e9ed;
        border: 1px solid #dbdfe6;
        box-shadow: 0px 1px 1px 0px rgba(174, 174, 180, 0.16);
      }
      .seeBtn {
        background: #e4e9ed;
        border: 1px solid #dbdfe6;
        box-shadow: 0px 1px 1px 0px rgba(174, 174, 180, 0.16);
        color: #3686ff;
        margin-top: 0.19rem;
      }
    }
  }
}
</style>
